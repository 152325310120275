import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import '../styles.scss'
export default function registerMachine() {
    return (
        <Layout>
                <div className="gallery-header">
                    <h4>Machine <span className="blue">Registration</span></h4>
                </div>
            <PaddedLayout>
                    <p>
                    We've built our reputation by providing the toughest, best-engineered, highest-value machines our industry can produce. We make sure that in every market in which our more than 350 distributors in 36 countries and service centers do business, they maintain a "factory" trained service department with a vested interest in our customers long-term satisfaction.
                    </p>
            </PaddedLayout>
        </Layout>
    )
}